<template>
  <MainLayout>
    <div class="row">
      <div class="col-12 d-flex flex-wrap online-id-container">
        <div class="title col-12">
          Congratulations!
        </div>
        <div class="content col-12">
          <p class="ext-margin">
            You have successfully signed your application. It is on the way to
            the bank already, we’ll notify you as soon as got the reply from the
            bank.
          </p>
          <p>
            Thank you for being with us, <br>
            Simplewealth
          </p>
          <p><br></p>
        </div>
        <a href="https://dashboard.simplewealth.ch/" class="btn btn-primary px-4 button-padding">Go to dashboard&nbsp;&nbsp; &rarr;</a>
      </div>
    </div>
  </MainLayout>
</template>

<style lang="scss" scoped>
.online-id-container {
  margin: 40px 0;
}
.title {
  font-family: "SuisseIntl-Medium";
  font-weight: 500;
  font-size: 33px;
  line-height: 42px;
  letter-spacing: -0.8px;
  color: #090d2e;
  margin-bottom: 32px;
}

.content {
  p {
    font-size: 16px;
    line-height: 24px;
    color: #676d9c;

    &.ext-margin {
      margin-bottom: 64px;
    }
  }
}
</style>

<script>
import MainLayout from "@/layouts/MainLayout.vue";

export default {
  components: {
    MainLayout,
  },
  name: "ThankYou",
};
</script>